<template>
  <Modal
    class-name="i-modal sales-good-model"
    :title="
      Type === 0
        ? '导出商品销售单'
        : Type === 1
        ? '导出发货单'
        : Type === 2
        ? '导出地址'
        : ''
    "
    v-model="isShow"
    width="600"
  >
    <Row :gutter="16" style="height: 100%">
      <i-col span="24" style="padding-left: 0">
        <Form ref="productSearchForm" :model="params" :label-width="70">
          <FormItem v-if="Type !== 0" label="排除商品:">
            <Input v-model="buttonInproduct" style="width: 84%" disabled />
            <Button
              type="primary"
              style="margin-left: 10px"
              @click="selectProductFun"
              >选择商品</Button
            >
          </FormItem>
          <FormItem v-if="Type !== 0" label="">
            <span style="color: red"
              >说明：该订单中包含排除商品的任意一个，整个订单都不导出</span
            >
          </FormItem>
          <FormItem class="select_product" v-if="Type === 0" label="">
            <Input v-model="buttonInproduct" disabled />
            <Button type="primary" @click="selectProductFun">选择商品</Button>
          </FormItem>
          <FormItem class="select_product" v-if="Type === 0" label="">
            <Checkbox v-model="only" label="仅包含选择商品"
              >仅包含选择商品</Checkbox
            >
          </FormItem>
          <FormItem label="开始时间:" prop="startDate">
            <DatePicker
              v-model="params.startDate"
              format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              style="width: 100%"
              placeholder="选择开始时间"
            ></DatePicker>
          </FormItem>
          <FormItem label="截止时间:" prop="endDate">
            <DatePicker
              v-model="params.endDate"
              format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
              type="datetime"
              placeholder="选择截止时间"
            ></DatePicker>
          </FormItem>
          <FormItem label="运费方式:" prop="freightType">
            <Select
              clearable
              v-model="params.freightType"
              placeholder="运费类型"
            >
              <Option
                v-for="(item, index) in freightTypeEnum"
                :key="index"
                :value="item.ID"
                >{{ item.CN }}</Option
              >
            </Select>
          </FormItem>
          <FormItem label="导出模版:" v-if="Type === 0" prop="modalTypes">
            <Select clearable v-model="params.modelType" placeholder="导出模版">
              <Option
                v-for="(item, index) in modalTypes"
                :key="index"
                :value="item.ID"
                >{{ item.CN }}</Option
              >
            </Select>
          </FormItem>
        </Form>
      </i-col>
    </Row>
    <template slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button
        v-if="!only"
        type="primary"
        :loading="loading"
        @click="handleExport"
        >导出</Button
      >
      <Button
        v-if="only"
        type="primary"
        :loading="loading"
        @click="handleOnlyExport"
        >导出</Button
      >
    </template>
    <sales-good-modal-products
      @getEmitProduct="getEmitProduct"
      ref="SalesGoodModalProducts"
    />
  </Modal>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import dayjs from "dayjs";
import SalesGoodModalProducts from "./sales-good-modal-products.vue";
export default {
  name: "sales-good-modal",
  data() {
    return {
      productTableLoading: false,
      tableHeight: 0,
      isShow: false,
      loading: false,
      iscustomer: false,
      Type: 0,
      tagColor: {
        0: "volcano",
        1: "success",
        2: "warning",
      },
      selectedProduct: [],
      modalTypes: [
        { ID: 1, CN: "德邦" },
        { ID: 2, CN: "中通" },
        { ID: 3, CN: "通用" },
      ],
      params: {
        startDate: "",
        endDate: "",
        freightType: 30,
        customer: false,
        modelType: 1,
        ids: [],
      },
      // rules: {
      //   startDate: [{ required: true, message: '优惠券类型不能为空', trigger: 'change' }],
      //   endDate: [{ required: true, message: '优惠类型不能为空', trigger: 'change' }]
      // },
      goodParams: {
        Code: "",
        Name: "",
        BrandID: "",
        ClassCode: "",
        Status: "",
        Page: 1,
        PageSize: 10,
        SortName: "CreateDate",
        SortOrder: "Desc",
      },
      productObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      only: false,
      productSelections: [],
      selectedProductSelections: [],
      sonSelectProducts: null,
      getEmitProductData: [],
    };
  },
  created() {},
  methods: {
    getEmitProduct(val) {
      this.getEmitProductData = val;
    },
    selectProductFun() {
      this.$refs.SalesGoodModalProducts.isShow = true;
    },

    async handleExport() {
      if (this.iscustomer) {
        this.params.customer = true;
      } else {
        this.params.customer = false;
      }
      if (!this.params.startDate) {
        this.$Message.error("请选择开始时间");
        return false;
      }
      if (!this.params.endDate) {
        this.$Message.error("请选择截止时间");
        return false;
      }
      if (this.getEmitProductData.length >= 0) {
        try {
          this.loading = true;
          this.params.ids = [];
          this.getEmitProductData.forEach((item) => {
            this.params.ids.push(item.ID);
          });
          this.params.startDate = dayjs(this.params.startDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.params.endDate = dayjs(this.params.endDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          if (this.Type === 0) {
            let filename = `商品销售单${dayjs(this.params.startDate).format(
              "YYYYMMDD"
            )}-${dayjs(this.params.endDate).format("YYYYMMDD")}.xls`;
            this.params.exportName = filename;
            await api.ExportProductExcel(this.params);
          } else if (this.Type === 1) {
            let filename = `发货单${dayjs(this.params.startDate).format(
              "YYYYMMDD"
            )}-${dayjs(this.params.endDate).format("YYYYMMDD")}.xls`;
            this.params.exportName = filename;
            await api.ExportExcel(this.params);
          } else if (this.Type === 2) {
            let filename = `收货地址${dayjs(this.params.startDate).format(
              "YYYYMMDD"
            )}-${dayjs(this.params.endDate).format("YYYYMMDD")}.xls`;
            this.params.exportName = filename;
            await api.ExportAddressExcel(this.params);
          }
          this.$Message.success("导出成功");
          this.loading = false;
        } catch (error) {
          this.$Notice.error({ title: "错误", desc: error });
          this.loading = false;
        }
      }
    },

    async handleOnlyExport() {
      if (this.getEmitProductData.length !== 0) {
        try {
          this.loading = true;
          this.params.ids = [];
          this.getEmitProductData.forEach((item) => {
            this.params.ids.push(item.ID);
          });
          this.params.startDate = dayjs(this.params.startDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.params.endDate = dayjs(this.params.endDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          let filename = `商品销售单${dayjs(this.params.startDate).format(
            "YYYYMMDD"
          )}-${dayjs(this.params.endDate).format("YYYYMMDD")}.xls`;
          this.params.exportName = filename;
          await api.ExportOnlyProductExcel(this.params);
          this.$Message.success("导出成功");
          this.loading = false;
        } catch (error) {
          this.$Notice.error({ title: "错误", desc: error });
          this.loading = false;
        }
      }
    },
  },
  computed: {
    freightTypeEnum() {
      return bizEnum.FreightTypeEnum;
    },
    buttonInproduct() {
      return this.getEmitProductData.map((item) => item.ID).join();
    },
  },
  components: { SalesGoodModalProducts },
};
</script>

<style lang="less">
.sales-good-model {
  .select_product {
    padding-left: 5px;
    .ivu-form-item-content {
      margin-left: 0px !important;
      display: flex;
      .ivu-input-wrapper {
        margin-right: 10px;
      }
    }
  }
  .ivu-modal-body {
    height: 500px;
  }
}
</style>
